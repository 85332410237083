import Head from 'next/head';
import React, { ReactElement } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  DefaultTheme,
  Heading,
  Icon,
  Spacing,
  Text,
  makeStyles
} from 'groundkeeper-component-library';

import AuthenticationArea from 'shared/App/components/AuthenticationArea';
import Layout from 'shared/App/components/Layout';
import Teaser from 'shared/App/components/Teaser';

import Globals from 'shared/App/utils/Globals';
import selectDefaultOrganization from 'shared/Redux/selectors/organization/selectDefaultOrganization';
import { FAQ } from './faq';
import Link from 'shared/App/components/Link';

import LandingSearchIcon from '../public/landing-search.svg';
import LandingAddIcon from '../public/landing-add.svg';
import LandingMapIcon from '../public/landing-map.svg';
import { Organization } from 'shared/Redux/interfaces';

type TeaserList = Array<{
  id: string;
  icon: any;
  href: string;
  title: string;
  text: string;
  moreLink?: string;
}>;

const TEASER = (intl: IntlShape, organization: Organization) => {
  const teaserList: TeaserList = [
    {
      id: 'groups-find',
      icon:
        organization?.setting_portal_flavor === 'bad_schwartau' ? (
          <LandingSearchIcon />
        ) : (
          <Icon color="secondary" name="search" />
        ),
      href: 'groups?unbookable=false',
      title: intl.formatMessage({
        id: 'groups_find',
        defaultMessage: 'Find groups'
      }),
      text: intl.formatMessage({
        id: 'groups_find_hint',
        defaultMessage: 'Just look for a group'
      }),
      moreLink: intl.formatMessage({
        id: 'group_teaser_search_more_link',
        defaultMessage: 'To search'
      })
    }
  ];

  if (organization?.setting_portal_booking_requests) {
    teaserList.push({
      id: 'request',
      icon:
        organization?.setting_portal_flavor === 'bad_schwartau' ? (
          <LandingAddIcon />
        ) : (
          <Icon color="secondary" name="textbox-plus" />
        ),
      title: intl.formatMessage({
        id: 'request_usage',
        defaultMessage: 'Request usage'
      }),
      href:
        organization?.setting_portal_flavor === 'bad_schwartau'
          ? '/requests/create'
          : '/requests/info',
      text: intl.formatMessage({
        id: 'request_usage_info_text',
        defaultMessage:
          'Apply for sports facilities to be transferred for use in accordance with SPAN'
      }),
      moreLink: intl.formatMessage({
        id: 'group_teaser_request_more_link',
        defaultMessage: 'To request form'
      })
    });
  }

  teaserList.push({
    id: 'groups-explore',
    icon:
      organization?.setting_portal_flavor === 'bad_schwartau' ? (
        <LandingMapIcon />
      ) : (
        <Icon color="secondary" name="map-marked" lib="fa" />
      ),
    title: intl.formatMessage({
      id: 'groups_explore',
      defaultMessage: 'Groups explore'
    }),
    href: '/map',
    text: intl.formatMessage({
      id: 'groups_explore_hint',
      defaultMessage:
        'Discover sports facilities and their locations on a map of Berlin'
    }),
    moreLink: intl.formatMessage({
      id: 'group_teaser_map_more_link',
      defaultMessage: 'To the map'
    })
  });

  return teaserList;
};

const useStyles = makeStyles((theme: DefaultTheme) => ({
  teaser: {
    display: 'flex',
    gap: theme.spacing(2),
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    [theme.mediaQueries.tiny]: {
      flexDirection: 'column'
    },
    '& li': {
      width: '33%',
      [theme.mediaQueries.small]: {
        width: '100%'
      }
    }
  }
}));

function Index() {
  const intl = useIntl();
  const classes = useStyles();
  const organization = useSelector(selectDefaultOrganization);

  return (
    <>
      <Head>
        <title>{`${Globals.settings.titlePrefix}${intl.formatMessage({
          id: 'sports_portal',
          defaultMessage: 'Sports portal Berlin'
        })}${Globals.settings.titlePostfix(organization)}`}</title>
      </Head>
      <AuthenticationArea />
      <Spacing y={2} />
      <Heading type="h1">
        {intl.formatMessage({
          id: 'welcome_to_sports_portal',
          defaultMessage: 'Welcome to sports portal Berlin!'
        })}
      </Heading>
      <>
        <Spacing y={2} />
        <section>
          <ul className={classes.teaser}>
            {TEASER(intl, organization).map(t => (
              <li key={t.id}>
                <Teaser
                  title={t.title}
                  text={t.text}
                  icon={t.icon}
                  href={t.href}
                  moreLink={t.moreLink}
                />
              </li>
            ))}
          </ul>
        </section>
      </>
      <Spacing y={4} />
      <Heading type="h2">
        {intl.formatMessage({
          id: 'faq',
          defaultMessage: 'Frequently asked Questions (FAQ)'
        })}
      </Heading>
      <section>
        {FAQ(organization)[0]
          .questions.filter((q, i) => i < FAQ(organization).length)
          .map(q => {
            const id = encodeURIComponent(q.title);

            return (
              <Accordion key={id}>
                <AccordionSummary aria-controls={id} id={id}>
                  <Text>{q.title}</Text>
                </AccordionSummary>
                <AccordionDetails>{q.text}</AccordionDetails>
              </Accordion>
            );
          })}
        <Text>
          {intl.formatMessage(
            {
              id: 'faq_hint',
              defaultMessage: 'Further information at'
            },
            {
              page: (
                <Link href="/faq">
                  {intl.formatMessage({
                    id: 'page',
                    defaultMessage: 'Page'
                  })}
                </Link>
              )
            }
          )}
        </Text>
      </section>
    </>
  );
}

Index.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>;
};

export default Index;
